// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

const moment = require("moment")
const toastr = require("toastr")

// const $ = require("../vendor/jquery.min")
// window.$ = $
// window.jQuery = $

// require("../vendor/bootstrap.bundle.min")
require('bootstrap')
require("../vendor/daterangepicker")
require("../vendor/jquery.fancybox")
require("../vendor/slick")

import { PageFlip } from 'page-flip'

import Typed from 'typed.js'

// const Selectr = require('mobius1-selectr/dist/selectr.min')

function calcTime (offset) {
  // create Date object for current location
  var d = new Date()
  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000)
  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + (3600000 * offset))
  // return nd.toLocaleString()
  return moment(nd).format('HH:mm')
}

document.addEventListener('turbolinks:load', () => {

  const menu = document.getElementById('menu-book')
  if (menu) {
    console.log('pageFlip', menu)
    window.pageFlip = new PageFlip(menu, {
      width: 500,
      height: 700,
      // showCover: true,
      // size: 'stretch'
    })
    window.pageFlip.loadFromHTML(document.querySelectorAll('.menu-page'));
  }

  if (location.pathname.indexOf('/en') > -1) {
    window.locale = 'en'
  } else if (location.pathname.indexOf('/ru') > -1) {
    window.locale = 'ru'
  }


  setTimeout(() => {
    $('.toast').toast({
      autohide: false
    })
    $('.toast').toast('show')
  }, 2000)

  // if ($('.typewriter').length) {
  //   var typed = new Typed('.typewriter', {
  //     strings: [locale == 'ru' ? "Высокие стандарты арктического гостеприимства!" : 'High standards of Arctic hospitality!'],
  //     typeSpeed: 50
  //   })
  // }

  if (pageYOffset) {
    $('body').addClass('scroll')
  }

  // const sectionImage = document.getElementById('sectionImage')
  // const logoFixedImg = document.getElementById('logoFixedImg')
  // if (sectionImage) {
  //   const sectionImageTop = sectionImage.offsetTop
  //   const sectionImageHeight = sectionImage.offsetHeight
  //   const logoTopBorder = 80
  //   const logoBottomBorder = 180
  //   window.addEventListener('scroll', () => {
  //     if (pageYOffset > (sectionImageTop - logoTopBorder) && pageYOffset < (sectionImageTop + sectionImageHeight - logoTopBorder)) {
  //       // logoFixedImg.style.filter = 'invert'
  //       logoFixedImg.setAttribute('style', 'filter: invert(1);')
  //     } else {
  //       logoFixedImg.removeAttribute('style')
  //     }
  //   })
  // }

  let hideTimeout = 0

  window.addEventListener('scroll', () => {

    // console.log(pageYOffset + 'px')
    if (pageYOffset === 0 && (location.pathname == '/' || location.pathname == '/en' || location.pathname == '/ru')) {
      $('body').removeClass('scroll')
    } else {

      // document.body.clientHeight - 3800
      // pageYOffset - 0 - 3433
      // const top = pageYOffset * 100 / document.body.clientHeight
      //
      // hideTimeout = setTimeout(() => {
      //   clearTimeout(hideTimeout)
      //   console.log('css');
      //   $('main').css('background-position', `left, center ${-top}%, center ${-top}%, top`)
      // }, 1000)

      if (!document.body.classList.contains('scroll')) {
        $('body').addClass('scroll')
      }
    }
  })


  $('#menuToggler').click((e) => {
    $('body').toggleClass('menu-open')
  })

  if ($('input[name="dates"]').length) {
    const options = {
      alwaysShowCalendars: true,
      parentEl: "#daterangepickerContainer",
      autoApply: true,
      minDate: moment().format('DD.MM.YYYY'),
      // endDate: moment().add(1, 'day').format('DD.MM.YYYY'),
    }
    if (locale == 'ru') {
      options.locale = {
        format: "DD.MM.YYYY",
        cancelLabel: 'Отменить',
        applyLabel: 'Применить',
        separator: " - ",
        fromLabel: "От",
        toLabel: "До",
        customRangeLabel: "Настроить",
        weekLabel: "Н",
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        firstDay: 1
      }
    }
    window.picker = $('input[name="dates"]').daterangepicker(options, (start, end, label) => {
      console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')')
    })

    // range update listener
    picker.on('apply.daterangepicker', (ev, picker) => {
      // $("#daterangepickerResult").html('Selected date range: ' + );
      $('#bookBtn').removeClass('invisible')
      $('#bookBtn').attr('href', `/${locale == 'en' ? 'en/' : ''}booking?startDate=${picker.startDate.format('YYYY-MM-DD')}&startTime=14:00&endDate=${picker.endDate.format('YYYY-MM-DD')}&endTime=12:00&hotelID=15&headOfficeID=9&hotelName=%22%D0%AE%D1%80%D0%B8%D0%B1%D0%B5%D0%B9%22`)
      $('#bookDates').text(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'))
    })
    // prevent hide after range selection
    picker.data('daterangepicker').hide = function () {}
    // show picker on load
    picker.data('daterangepicker').show()

    $('.daterangepicker .start-date.end-date').removeClass('today active start-date active end-date')

  }


  if ($('.slick-carousel').length) {
    // const options = JSON.parse($('.js-slick-carousel').attr('data-hs-slick-carousel-options'))
    $('.slick-carousel').slick({
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear'
    })
    $('.slick-carousel').on('afterChange', (event, slick, currentSlide, nextSlide) => {
      console.log('event, slick, currentSlide, nextSlide', event, slick, currentSlide, nextSlide)
      $('#currentSlide').text(currentSlide + 1)
    })
  }

  if ($('.restaurant-slick-carousel').length) {
    $('.restaurant-slick-carousel').slick({
      dots: true,
      centerMode: false,
      // focusOnSelect: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      // fade: true,
      // cssEase: 'linear'
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })
  }

  if ($('.menu-slick-carousel').length) {
    $('.menu-slick-carousel').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: false,
      autoplaySpeed: 3000,
      draggable: false,
      // fade: true,
      // cssEase: 'linear'
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })
  }


  $.ajax({
    url: 'https://api.openweathermap.org/data/2.5/weather?lat=66.54175514049375&lon=66.61323600885748&units=metric&appid=19769bd9801c1cb7327b04c16f1bd73d',
    success: (res) => {
      // console.log('res', res, res.main.temp)
      $('#temp').text(Math.round(res.main.temp))
    }
  })

  $('#time').text(calcTime(5))

})
//
//   if (document.getElementById('mySelect')) {
//     const selector = new Selectr('#mySelect', {
//       placeholder: 'Поиск услуг',
//       defaultSelected: false,
//       messages: {
//         noResults: 'Нет результатов'
//       }
//     })
//     selector.on('selectr.select', (option) => {
//       console.log('option', option)
//       // location.replace(`/services/${option.getAttribute('value')}`)
//       window.location.href = `/services/${option.getAttribute('value')}`
//     })
//   }
//
//   if ($('.js-slick-carousel').length) {
//     const options = JSON.parse($('.js-slick-carousel').attr('data-hs-slick-carousel-options'))
//     $('.js-slick-carousel').slick(options)
//   }
//
//   // $(':radio').change(function() {
//   //   console.log('New star rating: ' + this.value)
//   // })
//   if (document.getElementById('yashare2')) {
//     Ya.share2('yashare2')
//   }


// window.addReview = async (form) => {
//   try {
//     console.log('addReview', form)
//     $.ajax({
//       method: 'POST',
//       url: '/api/reviews.json',
//       data: $(form).serialize(),
//       success: (response) => {
//         console.log('response', response)
//         $('#addReviewModal').modal('hide')
//         toastr.success('Спасибо! Отзыв добавится после проверки модератором', 'Отзыв добавлен')
//       },
//       error: (response) => {
//         console.error('response error', response)
//       }
//     })
//   } catch (e) {
//     console.error(`${e.name}: ${e.message}`)
//   }
// }
//
// window.showReview = async (link) => {
//   try {
//     const review = JSON.parse($(link).attr('review'))
//     $('#review-avatar').attr('src', review.employee_avatar || '')
//     $('#review-name').text(review.employee_name || '')
//     $('#review-rating').empty()
//     for (var i = 0; i < review.stars; i++) {
//       $('#review-rating').append('<li class="list-inline-item mx-0"><i class="fas fa-star"></i></li>')
//     }
//     $('#review-advantages').text(review.advantages || '')
//     $('#review-disadvantages').text(review.disadvantages || '')
//     $('#review-duration').text(review.duration || '')
//     $('#review-dynamics').text(review.dynamics || '')
//     $('#review-text').text(review.text || '')
//     $('#reviewModal').modal('show')
//   } catch (e) {
//     console.error(`${e.name}: ${e.message}`)
//   }
// }
//
window.sendFeedback = async (form) => {
  try {
    console.log('sendFeedback', form)
    $.ajax({
      method: 'POST',
      url: '/api/feedbacks.json',
      data: $(form).serialize(),
      success: (response) => {
        console.log('response', response)
        $(form).find('input[type!="hidden"], textarea').val('')
        toastr.success('Спасибо! Мы свяжемся с Вами в течение рабочего дня')
      },
      error: (response) => {
        console.error('response error', response)
      }
    })
  } catch (e) {
    toastr.error(`${e.name}: ${e.message}`)
  }
}


window.changeReadMode = (url) => {
  console.log('changeReadMode')
  $.ajax({
    method: 'GET',
    url: `/read_mode?redirect=${url}`,
    success: (response) => {
      location.reload()
    },
    error: (response) => {
      toastr.error(response)
      console.error('response error', response)
    }
  })
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
